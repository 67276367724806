<template>
  <div>
    <v-card-title class="pb-0 green darken-4">
      <p class="body-1 font-weight-medium">
        Status
      </p>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col>
          <v-checkbox
            v-model="filters"
            label="Delivered"
            value="Delivered"
            @change="onCheckboxChange"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="filters"
            label="Sent"
            value="Sent"
            @change="onCheckboxChange"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="filters"
            label="Failed"
            value="Failed"
            @change="onCheckboxChange"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filters: [],
    }
  },
  mounted() {
    this.onCheckboxChange()
  },
  methods: {
    onCheckboxChange() {
      this.$emit('status-change', this.filters)
    },
  },

}
</script>

<style>

</style>
