<template>
  <div>
    <v-card-title class="pb-0 pt-2">
      <p class="body-1 font-weight-medium">
        Date
      </p>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-chip-group
          v-model="dateFilterLabel"
          active-class="primary white--text"
          column
          @change="onDateChipChange"
        >
          <v-chip
            v-for="dateFilter in dateFilters"
            :key="dateFilter"
            class="ml-2"
            :value="dateFilter"
          >
            {{ dateFilter }}
          </v-chip>
        </v-chip-group>
      </v-row>
      <v-row class="mt-7">
        <v-col
          cols="5"
        >
          <v-menu
            ref="fromDateMenu"
            v-model="fromDateMenu"
            :close-on-content-click="false"
            :return-value.sync="filters.fromDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filters.fromDate"
                label="From"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filters.fromDate"
              no-title
              scrollable
              :max="new Date().toISOString().substr(0, 10)"
              @change="
                onDateChange()
                $refs.fromDateMenu.save(filters.fromDate)
                fromDateMenu = false
              "
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="5"
        >
          <v-menu
            ref="toDateMenu"
            v-model="toDateMenu"
            :close-on-content-click="false"
            :return-value.sync="filters.toDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filters.toDate"
                label="To"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filters.toDate"
              no-title
              scrollable
              :max="new Date().toISOString().substr(0, 10)"
              @change="
                onDateChange()
                $refs.toDateMenu.save(filters.toDate)
                toDateMenu = false
              "
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    defaultFilter: {
      type: String,
      default: 'Last 30 days',
    },
  },
  data() {
    return {

      fromDateMenu: false,
      toDateMenu: false,
      dateFilterLabel: 'Last 30 days',
      dateFilters: [
        'Today',
        'Last 7 days',
        'Last 30 days',
        'Last 1 year',
      ],
      filters: {
        fromDate: '',
        toDate: '',
      },
    }
  },
  mounted() {
    this.initialize()
    this.emitDateChange()
  },
  methods: {
    initialize() {
      this.dateFilterLabel = this.defaultFilter
      switch (this.defaultFilter) {
        case 'Today':
          this.filters.fromDate = moment().format('YYYY-MM-DD')
          this.filters.toDate = moment().format('YYYY-MM-DD')
          break
        case 'Last 7 days':
          this.filters.fromDate = moment().subtract(7, 'days').format('YYYY-MM-DD')
          this.filters.toDate = moment().format('YYYY-MM-DD')
          break
        case 'Last 1 year':
          this.filters.fromDate = moment().subtract(1, 'year').format('YYYY-MM-DD')
          this.filters.toDate = moment().format('YYYY-MM-DD')
          break
        default:
          this.filters.fromDate = moment().subtract(30, 'days').format('YYYY-MM-DD')
          this.filters.toDate = moment().format('YYYY-MM-DD')
          break
      }
    },
    onDateChipChange(value) {
      let subtract
      switch (value) {
        case 'Last 7 days':
          subtract = 7
          break
        case 'Last 30 days':
          subtract = 30
          break
        case 'Last 1 year':
          subtract = 365
          break

        default:
          subtract = 0
          break
      }
      this.filters.fromDate = moment().subtract(subtract, 'days').format('YYYY-MM-DD')
      this.emitDateChange()
    },
    onDateChange() {
      const start = moment(this.filters.fromDate, 'YYYY-MM-DD')
      const end = moment(this.filters.toDate, 'YYYY-MM-DD')

      const differenceInDays = moment.duration(end.diff(start)).asDays()
      let label
      switch (differenceInDays) {
        case 0:
          label = 'Today'
          break
        case 7:
          label = 'Last 7 days'
          break
        case 30:
          label = 'Last 30 days'
          break
        case 365:
          label = 'Last 1 year'
          break

        default:
          label = `${start.format('Do MMM, YYYY')} - ${end.format('Do MMM, YYYY')}`
          break
      }
      this.dateFilterLabel = label
      this.emitDateChange()
    },
    emitDateChange() {
      const data = {
        filters: this.filters,
        label: this.dateFilterLabel,
      }
      this.$emit('date-change', data)
    },
  },

}
</script>

<style>

</style>
