<template>
  <div style="min-width: 511px">
    <v-card-title class="pb-0 green darken-4">
      <p class="body-1 font-weight-medium">
        Status
      </p>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col>
          <v-checkbox
            v-model="filters"
            label="No Debt"
            :value="1"
            @change="onCheckboxChange"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="filters"
            label="Debt"
            :value="2"
            @change="onCheckboxChange"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="filters"
            label="Credit"
            :value="3"
            @change="onCheckboxChange"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filters: [],
      filterLabel: 'all customers',
    }
  },
  mounted() {
    this.onCheckboxChange()
  },
  methods: {
    onCheckboxChange() {
      this.generateFilterLabel()
      const data = {
        filters: this.filters,
        label: this.filterLabel,
      }
      this.$emit('status-change', data)
    },
    generateFilterLabel() {
      const labels = []
      this.filters.forEach(element => {
        switch (element) {
          case 1:
            labels.push('no debt')
            break
          case 2:
            labels.push('debt')
            break
          case 3:
            labels.push('credit')
            break

          default:
            break
        }
      })
      if (labels.length === 0) {
        this.filterLabel = 'all customers'

        return
      }
      this.filterLabel = labels[0]
      if (labels.length === 2) {
        this.filterLabel = `${this.filterLabel} and ${labels[1]}`
      }
      if (labels.length === 3) {
        this.filterLabel = `${this.filterLabel}, ${labels[1]} and ${labels[2]}`
      }
    },
  },

}
</script>

<style>

</style>
